@import "./variables/colors";
@import "./variables/fonts";

:root {
    --heading1-size: 36px;
    --table-text-size: 18px;
    --legend-text-size: 14px;
}

html,
body {
    box-sizing: border-box;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
    text-rendering: geometricPrecision;
    margin: 0;
    padding: 0;
    font-family: $font-default;
    background: $background-base;
}
