:root {
    --white-primary: #fff;
    --white-primary-rgb: 255, 255, 255;
    --blue-primary: #00b5d4;
    --blue-primary-rgb: 0, 181, 212;
    --blue-secondary: #33c4dd;
    --blue-secondary-rgb: 51, 196, 221;
    --blue-tertiary: #73d7e8;
    --blue-tertiary-rgb: 115, 215, 232;
    --blue-quaternary: #A8E5F0;
    --blue-quaternary-rgb: 168, 229, 240;
    --blue-quinary: #CCEFF5;
    --blue-quinary-rgb: 204, 239, 245;
    --gray-primary: #151F29;
    --gray-primary-rgb: 34, 51, 67;
    --gray-secondary: #4e5c69;
    --gray-secondary-rgb: 78, 92, 105;
    --gray-tertiary: #e8eaec;
    --gray-tertiary-rgb: #232 234 236;
    --red-primary: #b8002e;//b8002e
}

$background-base: var(--gray-primary);
$text-default: var(--white-primary);
$text-secondary: var(--gray-secondary);
