$radius: 11px;

.legend_container {
    position: absolute;
    right: 0;
    top: 50px;
}

.legend_container ul li {
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--blue-primary);
    font-size: var(--legend-text-size);
    padding: 2px 0;
}

.legend_container ul li:before {
    content: "";
    position: relative;
    margin-right: 10px;
    width: $radius;
    height: $radius;
    border-radius: 100%;
    background-color: #000;
}

.legend_container ul li:nth-of-type(1):before {
    background-color: var(--blue-quinary);
}

.legend_container ul li:nth-of-type(2):before {
    background-color: var(--blue-tertiary);
}

.legend_container ul li:nth-of-type(3):before {
    background-color: var(--blue-primary);
}
