.dataColumn {
    padding-right: 30px;
    // font-size: 20px;
}

.dataColumn > header > hr {
    width: 100%;
    position: relative;
    z-index: 0;
    background: var(--gray-secondary);
    height: 2px;
    border: none;
    flex-shrink: 0;
}
