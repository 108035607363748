.table_container {
    --grid-size: 29px;
    --scale: 1;
    position: absolute;
    top: calc(50px * var(--scale)); // 50px(outer) + 70px(header) + 38px(hr)
    display: grid;
    grid-template-columns: calc(var(--scale) * 50px) auto;
    grid-template-rows: calc(var(--scale) * 44px) max-content;
}

.table_container > header {
    grid-column: 1;
    grid-row: 2;
    position: relative;
    display: grid;
    grid-template-rows: repeat(4, calc(var(--scale) * 29px));
}

.table_container > header > span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: scale(var(--scale));
}

.table_container ul {
    display: flex;
    padding: 0;
    margin: 0;
    grid-column: 2;
}

// .table_container ul li {
//     list-style: none;
//     display: grid;
//     grid-template-rows: 44px repeat(4, var(--grid-size));
//     grid-template-columns: auto;
//     font-size: var(--table-text-size);
// }

// .table_container ul li header {
//     color: var(--gray-secondary);
//     grid-row: 1;
//     display: block;
//     height: min-content;
// }

// .table_container ul li span {
//     display: flex;
//     align-items: center;
// }

.embla {
    overflow: hidden;
    position: relative;
    height: calc(var(--scale) * 160px);    
    left: -10px;
    width: calc(var(--scale) * 550px);
}
.embla__container {
    display: flex;
    padding: 0;
    margin: 0;
    grid-column: 2;
}
.embla__slide {
    flex: 0 0 100px;
    position: relative;
    width: min-content;
}

.embla__slide > li {
    list-style: none;
    display: grid;
    grid-template-rows: calc(var(--scale) * 44px) repeat(4, calc(var(--scale) * 29px));
    grid-template-columns: auto;
    font-size: calc(var(--scale) * var(--table-text-size));
}

.embla__slide header {
    color: var(--gray-secondary);
    grid-row: 1;
    display: block;
    height: min-content;
}

.embla__slide span { 
    display: flex;
    align-items: center;
    white-space: nowrap;
}

@media only screen and (min-width: 1440px) {

    .table_container {
        --scale: 1.3;
    }
  }
