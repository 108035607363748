.planContainer {
    height: 100%;
    // width: 100%;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
}

.planContainer svg {
    height: 100%;
    width: auto;
    max-width: calc(100vw - 120px);
    z-index: 1;
    position: relative;
}
