@import "src/styles/variables/colors";

.layoutContainer {
    background: $background-base;
    margin: 50px 60px;
    display: flex;
    flex-direction: column;
    color: $text-default;
    height: calc(100vh - 100px);
}

.layoutContainer > header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 60px;
}

.layoutContainer > header > span {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
}

.layoutContainer > header > span > i > svg {
    vertical-align: bottom;
}

.layoutContainer section {
    flex-grow: 1;
    position: relative;
}

.layoutContainer footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.layoutContainer footer > span {
    flex-grow: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
}

.layoutContainer footer > span:nth-of-type(2) {
    margin-right: 74px;
}

.layoutContainer footer > span:nth-of-type(2) > span {
    position: absolute;
    right: 0;
    transform: translateX(calc(100% + 14px));
}

.layoutContainer footer .horizontalBar {
    margin: 0 20px;
    flex-shrink: 1;
}

.horizontalBar {
    width: 100%;
    position: relative;
    z-index: 0;
    background: $text-secondary;
    height: 2px;
    border: none;
    margin-top: 30px;
    flex-shrink: 0;
}
